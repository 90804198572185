import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql, Link } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha"
import { withFormik, Form, Field } from "formik"
import * as Yup from "yup"
import { useMutation } from "@apollo/react-hooks"
import gql from "graphql-tag"
import "isomorphic-fetch"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../graphql/fragments"

import { HeadingBasic } from "../../components/heading/heading"
import {
  WpGroup,
  WpIconlist,
  Stats,
  Carousel,
} from "../../components/utils/render-functions"
import { CoverFeatured } from "../../components/cover-featured/cover-featured"
import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs"
import { CoverMain } from "../../components/cover-main/cover-main"
import { Container, Section } from "../../components/grid/grid"
import { lang } from "moment"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const SUBMIT = gql`
  mutation Submit(
    $gender: String
    $firstName: String
    $lastName: String
    $subject: String
    $message: String
    $email: String
    $address: String
    $phoneNumber: String
    $companyName: String
  ) {
    submit2FormInvestor(
      input: {
        clientMutationId: ""
        gender: $gender
        firstName: $firstName
        lastName: $lastName
        subject: $subject
        message: $message
        email: $email
        address: $address
        phoneNumber: $phoneNumber
        companyName: $companyName
      }
    ) {
      status
      message
    }
  }
`

const InvestorRelations = ({ location }) => {
  const [formValues, setFormValues] = useState({
    gender: "Mr",
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    phoneNumber: "",
    subject: "",
    message: "",
  })
  const [status, setStatus] = useState()
  const data = useStaticQuery(graphql`
    query {
      imgCoverMain: file(relativePath: { eq: "imgBannerSinarmas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      wordPress {
        pageBy(uri: "investor-relations") {
          id
          title
          slug
          blocks {
            ...AcfGalleryBlock
            ...CoreGroupBlock
            ...CoreParagraphBlock
            ...CoreHeadingBlock
            ...CoreCoverBlock
            ...CoreShortcodeBlock
            ... on WordPress_AcfFormBlock {
              name
            }
          }
          translation(language: ID) {
            title
            blocks {
              ...CoreParagraphBlock
              ...CoreHeadingBlock
              ...CoreButtonBlock
              ...CoreCoverBlock
              ...CoreShortcodeBlock
              ...AcfIconListBlock
              ...CoreGroupBlock
            }
            seo {
              title
              metaDesc
              twitterTitle
              twitterDescription
              opengraphTitle
              opengraphDescription
            }
          }
          childPages {
            nodes {
              title
              slug
              excerpt
              featuredImage {
                sourceUrl
                title
                caption
              }
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const [submitData, { loading }] = useMutation(SUBMIT)

  const InvestorForm = ({ errors, touched }) => {
    const button = submitStatus => {
      if (submitStatus === "success") {
        return (
          <>
            <button
              disabled
              className="btn btn-success btn-block mt-4"
              type="submit"
            >
              <i className="fas fa-check"></i>
            </button>
            <div className="d-flex justify-content-center pt-3">
              <HeadingBasic text={["Your message has been delivered"]} />
            </div>
          </>
        )
      } else if (submitStatus === "error") {
        return (
          <>
            <button
              disabled={loading}
              className="btn btn-primary btn-block mt-4"
              type="submit"
            >
              {"Kirim"}
            </button>
            <div className="d-flex justify-content-center pt-3">
              <HeadingBasic text={["Something went wrong"]} />
            </div>
          </>
        )
      } else {
        return (
          <button
            disabled={loading}
            className="btn btn-primary btn-block mt-4"
            type="submit"
          >
            {loading ? <i className="fa fa-spinner fa-spin"></i> : "Kirim"}
          </button>
        )
      }
    }
    return (
      <Section key="investors-form" className="pt-0">
        <Container className="mw-lg">
          {/* <div className="investor-form-heading">Gender</div> */}
          <div className="row">
            <Field
              name="gender"
              render={({ field }) => {
                return (
                  <div className="col-11 col-lg-6 d-flex justify-content-between mb-3 mb-lg-4">
                    <label className="radio-wrapper">
                      Mr
                      <input
                        {...field}
                        id="investor"
                        type="radio"
                        checked={field.value === "Mr"}
                        name="gender"
                        value="Mr"
                      />
                      <span className="radio-button" />
                    </label>
                    <label className="radio-wrapper">
                      Ms
                      <input
                        {...field}
                        id="investor"
                        type="radio"
                        checked={field.value === "Ms"}
                        name="gender"
                        value="Ms"
                      />
                      <span className="radio-button" />
                    </label>
                    <label className="radio-wrapper">
                      Mrs
                      <input
                        {...field}
                        id="investor"
                        type="radio"
                        name="gender"
                        value="Mrs"
                        checked={field.value === "Mrs"}
                      />
                      <span className="radio-button" />
                    </label>
                  </div>
                )
              }}
            />
          </div>

          <Form>
            <div className="row">
              <div className="col-lg-6 mb-3 mb-lg-4">
                <Field
                  type="text"
                  placeholder={"Nama Depan"}
                  className="form-control px-0"
                  name="firstname"
                />
                {touched.firstname && errors.firstname && (
                  <div className="invalid-value d-block">
                    {errors.firstname}
                  </div>
                )}
              </div>
              <div className="col-lg-6 mb-3 mb-lg-4">
                <Field
                  className="form-control px-0"
                  placeholder={"Nama Belakang"}
                  type="text"
                  name="lastname"
                />
                {touched.lastname && errors.lastname && (
                  <div className="invalid-value d-block">{errors.lastname}</div>
                )}
              </div>
              <div className="col-lg-6 mb-3 mb-lg-4">
                <Field
                  className="form-control px-0"
                  placeholder={"Perusahaan"}
                  type="text"
                  name="companyname"
                />
                {touched.companyname && errors.companyname && (
                  <div className="invalid-value d-block">
                    {errors.companyname}
                  </div>
                )}
              </div>
              <div className="col-lg-6 mb-3 mb-lg-4">
                <Field
                  className="form-control px-0"
                  placeholder={"Nomor Telepon"}
                  type="number"
                  name="phonenumber"
                />

                {touched.phonenumber && errors.phonenumber && (
                  <div className="invalid-value d-block">
                    {errors.phonenumber}
                  </div>
                )}
              </div>
              <div className="col-lg-6 mb-3 mb-lg-4">
                <Field
                  className="form-control px-0"
                  placeholder="Email"
                  type="email"
                  name="email"
                />
                {touched.email && errors.email && (
                  <div className="invalid-value d-block">{errors.email}</div>
                )}
              </div>
              <div className="col-lg-6 mb-3 mb-lg-4">
                <Field
                  className="form-control px-0"
                  placeholder={"Alamat (Tidak Wajib)"}
                  type="text"
                  name="address"
                />
              </div>
              <div className="col-lg-12 mb-3 mb-lg-4">
                <Field
                  className="form-control px-0"
                  placeholder={"Subyek"}
                  type="text"
                  name="subject"
                />
                {touched.subject && errors.subject && (
                  <div className="invalid-value d-block">{errors.subject}</div>
                )}
              </div>
              <div className="col-12">
                <Field
                  as="textarea"
                  className="form-control px-0"
                  placeholder={"Pesan"}
                  name="message"
                ></Field>
                {touched.message && errors.message && (
                  <div className="invalid-value d-block">{errors.message}</div>
                )}
              </div>
              <div className="col-12 my-4">
                <Field name="recaptcha">
                  {({ field, form }) => (
                    <ReCAPTCHA
                      sitekey={process.env.RECAPTCHA_SITE_KEY}
                      onChange={value => {
                        form.setFieldValue("recaptcha", value)
                      }}
                    />
                  )}
                </Field>
                {touched.recaptcha && errors.recaptcha && (
                  <div className="invalid-value d-block">
                    {errors.recaptcha}
                  </div>
                )}
              </div>
            </div>
            {button(status)}
          </Form>
        </Container>
      </Section>
    )
  }

  const FormikApp = withFormik({
    mapPropsToValues({
      firstname,
      lastname,
      companyname,
      phonenumber,
      address,
      subject,
      message,
      gender,
      email,
      recaptcha,
    }) {
      return {
        firstname: firstname || formValues.firstName,
        lastname: lastname || formValues.lastName,
        companyname: companyname || formValues.companyName,
        phonenumber: phonenumber || formValues.phoneNumber,
        address: address || formValues.address,
        subject: subject || formValues.subject,
        message: message || formValues.message,
        gender: gender || formValues.gender,
        email: email || formValues.email,
        recaptcha: recaptcha || "",
      }
    },
    validationSchema: Yup.object().shape({
      firstname: Yup.string().required("Required"),
      lastname: Yup.string().required("Required"),
      email: Yup.string()
        .email()
        .required("Required"),
      companyname: Yup.string().required("Required"),
      phonenumber: Yup.number().required("Required"),
      subject: Yup.string().required("Required"),
      message: Yup.string().required("Required"),
      recaptcha: Yup.string().required(
        "Please verify that you are not a robot"
      ),
    }),
    handleSubmit(values) {
      setFormValues({
        gender: values.gender,
        firstName: values.firstname,
        lastName: values.lastname,
        subject: values.subject,
        message: values.message,
        email: values.email,
        address: values.address,
        phoneNumber: values.phonenumber,
        companyName: values.companyname,
      })

      // fetch("https://sml-bismite-yk88.antikode.dev/submit/index.php", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Accept: "application/json",
      //     mode: "no-cors",
      //   },
      //   body: JSON.stringify({ query: "{ get-csrf }" }),
      // })
      //   .then(r => r.json())
      //   .then(data => console.log("data returned:", data))

      setTimeout(() => {
        submitData({
          variables: {
            gender: values.gender,
            firstName: values.firstname,
            lastName: values.lastname,
            subject: values.subject,
            message: values.message,
            email: values.email,
            address: values.address,
            phoneNumber: values.phonenumber,
            companyName: values.companyname,
          },
        })
          .then(res => {
            if (res.data.submit2FormInvestor.status === "success") {
              setStatus("success")
            }
          })
          .catch(err => {
            console.log(err)
            setStatus("error")
          })
      }, 500)
    },
  })(InvestorForm)

  const wordPress =
    data?.wordPress?.pageBy?.translation?.blocks ||
    data?.wordPress?.pageBy?.blocks
  const seo =
    data?.wordPress?.pageBy?.translation?.seo || data?.wordPress?.pageBy?.seo
  const cover =
    wordPress &&
    wordPress.find(x => x.name === "core/cover") === wordPress[0] &&
    wordPress[0]

  return (
    <Layout location={location} currentLocation={location.pathname} lang="ID">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle}
        twitterTitle={seo?.twitterTitle}
        twitterDescription={seo?.twitterDescription}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        url={location?.href}
        fbImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        twitterImg={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
        img={
          cover
            ? cover.attributes.url
            : data.imgVideoCover.childImageSharp.fluid
        }
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>

      {cover && (
        <CoverMain
          h1={
            data?.wordPress?.pageBy?.translation?.title ||
            data?.wordPress?.pageBy?.title
          }
          img={
            cover
              ? cover.attributes.url
              : data.imgCoverMain.childImageSharp.fluid
          }
          alt={
            cover && cover.innerBlocks.find(x => x.name === "core/heading")
              ? cover.innerBlocks.find(x => x.name === "core/heading")
                  .attributes.content
              : "Title"
          }
          className="cover-md"
        >
          <Breadcrumbs path={location.pathname} className="breadcrumb-light" />
        </CoverMain>
      )}

      {wordPress &&
        wordPress.map((item, i) => {
          switch (item.name) {
            case "core/heading":
              return (
                <div key={`heading-${i}`} className="container">
                  <div className={i === 0 ? "py-main" : ""}>
                    <HeadingBasic h2={item.attributes.content} />
                  </div>
                </div>
              )
            case "core/buttons":
              if (
                item.innerBlocks[0].attributes.url &&
                item.innerBlocks[0].attributes.url.split(".").length > 1
              ) {
                return (
                  <div key={`button=${i}`} className="container">
                    <div className={i === 0 ? "py-main" : "pb-main"}>
                      <HeadingBasic>
                        <a
                          href={item.innerBlocks[0].attributes.url}
                          target="_blank"
                          className="btn btn-link fx-underline"
                          rel="noopener noreferrer"
                          key={`${item.innerBlocks[0].attributes.url}-${i}`}
                        >
                          {item.innerBlocks[0].attributes.text} &nbsp;
                          <i className="far fa-external-link"></i>
                        </a>
                      </HeadingBasic>
                    </div>
                  </div>
                )
              } else {
                return (
                  <div key={`button-${i}`} className="container">
                    <Link
                      key={`${item.innerBlocks[0].attributes.url}-${i}`}
                      to={item.innerBlocks[0].attributes.url}
                      className="btn btn-link fx-underline"
                    >
                      {item.innerBlocks[0].attributes.text}
                    </Link>
                  </div>
                )
              }
            case "core/paragraph":
              return (
                <div key={`headingbasic-${i}`} className="py-main">
                  <div className="container container-md">
                    <HeadingBasic text={[item.attributes.content]} />
                  </div>
                </div>
              )
            case "core/group":
              return (
                <div key={`${item.name}-${i}`} className="py-main">
                  <WpGroup data={item} />
                </div>
              )
            case "acf/iconlist":
              return (
                <div
                  key={`iconlist-${i}`}
                  className={
                    i === 0 ? "container py-main" : "container pb-main"
                  }
                >
                  <WpIconlist data={item} />
                </div>
              )
            case "acf/labelvalues":
              return (
                <div key={`${item.name}-${i}`} className="pb-main">
                  <Stats data={item} />
                </div>
              )
            case "core/cover":
              if (i !== 0)
                return (
                  <div key={`featured-${i}`} className="pb-main">
                    <CoverFeatured
                      label="Featured News"
                      img={
                        item.attributes.url ||
                        data.featured.childImageSharp.fluid
                      }
                      title={item.innerBlocks[0].attributes.content}
                      text={item.innerBlocks[1].attributes.content}
                      btnText={
                        item.innerBlocks[2].innerBlocks[0].attributes.text
                      }
                      btnLink={
                        item.innerBlocks[2].innerBlocks[0].attributes.url
                      }
                    />
                  </div>
                )

              return <div key={i}></div>
            case "acf/acfgallery":
              return (
                <div key={`gallery-${i}`} className="container">
                  <Carousel data={item} />
                </div>
              )
            case "core/shortcode":
              if (item.attributes.text === "investors-form") {
                return <FormikApp key="formikapp" />
              }
            default:
              return <div key={`default-${i}`}></div>
          }
        })}
    </Layout>
  )
}

export default InvestorRelations
